export function isUrlOnMyBusiness(url: string): boolean {
  // allow match for /foretag/mybusiness and /foretag/mybusiness/ followed by anything
  const matches = url.match(/\/foretag\/mybusiness$|\/foretag\/mybusiness\/.*/);
  if (matches && matches.length > 0) {
    return true;
  }
  return false;
}

export function insertScopeInPath(scopeId: string, path: string) {
  const pathArr = path.split("/");
  // The path looks like /foretag/mybusiness/XXX, here we insert scopeId between mybusiness and XXX.
  pathArr.splice(3, 0, scopeId);
  let result = pathArr.toString();
  result = result.replaceAll(",", "/");
  return result;
}
