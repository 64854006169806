import { urlContainsScope } from "@telia/b2b-customer-scope";
import { logInfo } from "@telia/b2x-logging";
import { isCurrentUrlExcludedFromRedirecting } from "../utils/constants";
import { isUrlOnMyBusiness } from "../utils/utils";
import { packageName } from "./utils";

declare global {
  interface Window {
    ignoreNavigationTemp: boolean;
  }
}

export const mount = async () => {
  window.addEventListener("single-spa:before-routing-event", handleRoute);
};
export const unmount = () => {
  window.removeEventListener("single-spa:before-routing-event", handleRoute);
  return Promise.resolve();
};
export const bootstrap = () => {
  return Promise.resolve();
};

function handleRoute({
  detail: { oldUrl, newUrl, navigationIsCanceled, cancelNavigation },
}: any): void {
  const onMyBusiness = isUrlOnMyBusiness(newUrl);
  const isNotExcluded = !isCurrentUrlExcludedFromRedirecting(newUrl);
  const url = new URL(newUrl);
  const scopeIdExists = urlContainsScope(url.pathname);
  if (
    isNotExcluded &&
    onMyBusiness &&
    !scopeIdExists &&
    !navigationIsCanceled &&
    !window.ignoreNavigationTemp
  ) {
    logInfo(packageName, `Before SSPA routing event from ${oldUrl} to ${newUrl}`);
    window.ignoreNavigationTemp = true;
    cancelNavigation();
    window.location.href = newUrl;
  }
}
