import {
  isTestEnv,
  B2B_ORGANISATION_REGISTRATION_BASE_URL,
  B2B_ORGANISATION_REGISTRATION_NOT_LOGGED_IN_URL,
  B2B_ORGANISATION_REGISTRATION_LOGGED_IN_URL,
  B2B_TERMS_URL,
  B2B_START_PAGE_URL,
  B2B_NO_ACCESS_URL,
  B2B_NOT_FOUND_404_URL,
  B2B_GENERAL_ERROR_URL,
  B2B_REQUEST_NEW_PASSWORD_URL,
  B2B_FORGOT_USERNAME_URL,
  TELIA_ADMIN_URL,
  INVITE_URL,
} from "@telia/b2b-utils";
const EXCLUDED_URLS_BASE = [
  B2B_ORGANISATION_REGISTRATION_BASE_URL,
  B2B_ORGANISATION_REGISTRATION_NOT_LOGGED_IN_URL,
  B2B_ORGANISATION_REGISTRATION_LOGGED_IN_URL,
  B2B_TERMS_URL,
  B2B_START_PAGE_URL,
  B2B_NO_ACCESS_URL,
  B2B_NOT_FOUND_404_URL,
  B2B_GENERAL_ERROR_URL,
  B2B_REQUEST_NEW_PASSWORD_URL,
  B2B_FORGOT_USERNAME_URL,
  TELIA_ADMIN_URL,
  INVITE_URL,
];
export const COTS_HOSTNAME_MATCHER = "cots.";

const EXCLUDED_URLS_TEST = [...EXCLUDED_URLS_BASE];

const EXCLUDED_URLS_PROD = [...EXCLUDED_URLS_BASE];

export const EXCLUDED_URLS = isTestEnv() ? EXCLUDED_URLS_TEST : EXCLUDED_URLS_PROD;

export const isCurrentUrlExcludedFromRedirecting = (currentUrl: string): boolean => {
  const match = EXCLUDED_URLS.find((url) => {
    return currentUrl.includes(url);
  });
  return match ? true : false;
};

export function getRequestQueryParamOrNull(
  key: string,
  location: URL = new URL(window.location.href)
): string | null {
  const urlSearchParams = new URLSearchParams(location.search);
  return urlSearchParams.get(key);
}
